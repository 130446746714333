import React, { useEffect, useState} from "react"
// import { useMediaQuery } from "react-responsive"
import { navigate } from "gatsby"

import Footer from "../components/quilmesRock/Footer"
import Hero from "../components/quilmesRock/Hero"
import Informacion from "../components/quilmesRock/Informacion"
import Lineup from "../components/quilmesRock/Lineup"
import Merchandising from "../components/quilmesRock/Merchandising"
import Promociones from "../components/quilmesRock/Promociones"
import Sponsors from "../components/quilmesRock/Sponsors"
import Tickets from "../components/quilmesRock/Tickets"

import Modal from "../components/quilmesRock/Modal"

export default function Quilmesrock() {
  // const mobile = useMediaQuery({ query: "(max-width: 767px)" })

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local)
      navigate("/agegate", { state: { newPath: "/quilmesrock" } })
  }, [])

  useEffect(()=>{
    setTimeout(()=>{
      handleShow()
    },3000)
  },[])

  return (
    <div styles={{ backgroundColor: "#031532" }}>
      <Modal show={show} handleClose={handleClose} handleShow={handleShow} />
      <Hero />
      <Lineup />
      <Informacion />
      <Sponsors />
      <Merchandising />
      <Tickets />
      <Promociones />
      <Footer />
    </div>
  )
}
