import React from "react"
import sectionStyles from "../../styles/quilmesrock/merchandising.module.css"

import border from "../../images/quilmesrock/bottom.png"
import arrow from "../../images/quilmesrock/arrowright.svg"
import { Container, Row, Col, Button, Image } from "react-bootstrap"

export default function Merchandising() {
  return (
    <div className={sectionStyles.container} name="lineup">
      <div className={sectionStyles.content}>
        <div className={sectionStyles.column}>
          <h4 className={sectionStyles.titulo}>MERCHANDISING</h4>
          <h2 className={sectionStyles.subtitulo}>NUESTRO MERCH</h2>
          <img src={border} alt="line" />
        </div>
      </div>

      <Container fluid>
        <Row className="d-flex justify-content-center align-items-start">
          {/* <Col xs={12} md={2}>
            <Row className={sectionStyles.rowcolumn}>
              <Col xs={12} className={sectionStyles.colimg}>
                <div className={sectionStyles.sweaters}>
                  <a href="/quilmesrock" className="text-decoration-none"><Button className={sectionStyles.boton}>Sweaters </Button></a>
                </div>
              </Col>
              <Col xs={12} className={sectionStyles.colimg}>
                <div className={sectionStyles.scarves}>
                  <a href="/quilmesrock" className="text-decoration-none"> <Button className={sectionStyles.boton}>Scarves</Button></a>
                </div>
              </Col>
            </Row>
          </Col> */}
          <Col xs={12} md={3}>
            <Row className={sectionStyles.rowcolumn}>
              <Col xs={12} className={sectionStyles.colimg}>
                <a
                  href="https://www.honkytonkshop.com/productos/quilmes-rock-people/?variant=450642454"
                  className="text-decoration-none"
                >
                  <div className={sectionStyles.accesorios}>
                    {" "}
                    {/* <Button className={sectionStyles.boton}>Accesorios</Button> */}
                  </div>
                </a>
              </Col>
              <Col xs={12} className={sectionStyles.colimg}>
                <a
                  href="https://www.honkytonkshop.com/productos/quilmes-rock-horse/"
                  className="text-decoration-none"
                >
                  <div className={sectionStyles.remeras}>
                    {/* <Button className={sectionStyles.boton}>Remeras</Button>*/}
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={3}>
            <Row className={sectionStyles.rowcolumn}>
              <Col xs={12} className={sectionStyles.colimg}>
                <a
                  href="https://www.honkytonkshop.com/productos/buzo-quilmes-rock-by-honky-tonk-oversize/"
                  className="text-decoration-none"
                >
                  <div className={sectionStyles.buzos}>
                    {/* <Button className={sectionStyles.boton}>Buzos</Button> */}
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={2}>
            <Row className={sectionStyles.rowcolumn}>
              <Col xs={12} className={sectionStyles.colimg}>
                <a
                  href="https://www.honkytonkshop.com/productos/quilmes-rock-2022/"
                  className="text-decoration-none"
                >
                  <div className={sectionStyles.gorras}>
                    {" "}
                    {/* <Button className={sectionStyles.boton}>Gorras</Button> */}
                  </div>
                </a>
              </Col>
              <Col xs={12} className={sectionStyles.colimg}>
                <a
                  href="https://www.honkytonkshop.com/productos/quilmes-rock-circle-oversize/"
                  className="text-decoration-none"
                >
                  <div className={sectionStyles.mochilas}>
                    {/* <Button className={sectionStyles.boton}>Mochilas</Button> */}
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className={sectionStyles.merch}>
          <a
            href="https://www.honkytonkshop.com/quilmesrockbyhonkytonk/?mpage=2/"
            className="text-decoration-none"
          >
            <h4 className={sectionStyles.next}>NUESTRO MERCH</h4>
          </a>
          <img src={arrow} alt="" />
        </Row>
      </Container>
    </div>
  )
}
