import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Image } from "react-bootstrap"
import styles from "../../styles/quilmesrock/sponsors.module.css"
import sectionStyles from "../../styles/quilmesrock/section.module.css"
import { IsMobileDevice } from "../../../utils/ismobile"

import border from "../../images/quilmesrock/bottom.png"
import sponsor1 from "../../images/quilmesrock/sponsor1.png"
import sponsor2 from "../../images/quilmesrock/sponsor2.png"
import sponsormobile from "../../images/quilmesrock/sponsormobile.png"
import sponsorM from "../../images/quilmesrock/sponsorM.png"
import didi from "../../images/quilmesrock/sponsors/didi.png"
import pedidosya from "../../images/quilmesrock/sponsors/pedidosya.png"
import correoargentino from "../../images/quilmesrock/sponsors/correoargentino.png"
import solodeportes from "../../images/quilmesrock/sponsors/solodeportes.png"

import aireuropa from "../../images/quilmesrock/sponsors/aireuropa.png"
import areaticket from "../../images/quilmesrock/sponsors/areaticket.png"
import claro from "../../images/quilmesrock/sponsors/claro.png"
import enigma from "../../images/quilmesrock/sponsors/enigma.art.png"
import popart from "../../images/quilmesrock/sponsors/popart.png"

export default function Sponsors() {
  const mobile = useMediaQuery({ query: "(max-width: 767px)" })
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div className={sectionStyles.container} name="sponsors">
      <div className={sectionStyles.column}>
        <h4 className={sectionStyles.titulo}>SPONSORS</h4>
        <h2 className={sectionStyles.subtitulo}>NUESTROS SPONSORS</h2>
        <img src={border} alt="" />
      </div>
      <div className={styles.sponsors}>
        {/* <Image fluid src={sponsorM} className={styles.linkmobile} alt="" />
        <Image
          className={styles.linkdesktop}
          fluid
          src={sponsor1}
          alt=""
          style={{ marginTop: "50px" }}
        />
        <div className={mobile ? styles.middleLineM : styles.middleLine}> </div> */}
        {isMobile ? (
          <>
            <div className={styles.secondLineSponsorContainerM}>
              <div
                className={styles.imageContainerM}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "50%",
                  padding: "20px 25px",
                }}
              >
                <Image fluid src={claro} />
              </div>
              <div
                className={styles.imageContainerM}
                style={{
                  width: "50%",
                  padding: "20px 25px",
                  // padding: mobile ? "12px" : "20px",
                }}
              >
                <Image fluid src={pedidosya} />
              </div>
            </div>

            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>
            <div className={styles.secondLineSponsorContainerM}>
              <div
                className={styles.imageContainerM}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "50vw",
                  padding: "20px 25px",
                }}
              >
                <Image fluid src={didi} />
              </div>
              <div
                className={styles.imageContainerM}
                style={{
                  width: "50vw",
                  padding: "15px 40px",
                }}
              >
                <Image fluid src={solodeportes} />
              </div>
            </div>
            {/* -------- SEGUNDA LINEA -------*/}
            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>

            <div className={styles.secondLineSponsorContainerM}>
              <div
                className={styles.imageContainerM}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "50%",
                  padding: mobile ? "25px" : "28px",
                }}
              >
                <Image fluid src={aireuropa} />
              </div>
              <div
                className={styles.imageContainerM}
                style={{
                  width: "50%",
                  padding: mobile ? "25px" : "28px",
                }}
              >
                <Image fluid src={correoargentino} />
              </div>
            </div>
            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>
            <div className={styles.secondLineSponsorContainerM}>
              <div
                className={styles.imageContainerM}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "50vw",
                  padding: mobile ? "25px" : "28px",
                }}
              >
                <Image fluid src={enigma} />
              </div>
              <div
                className={styles.imageContainerM}
                style={{
                  width: "50vw",
                  padding: "10px 40px",
                }}
              >
                <Image fluid src={areaticket} />
              </div>
            </div>
            {/* -------- TERCERA LINEA -------*/}
            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>
            <div className={styles.secondLineSponsorContainerM}>
              <div
                className={styles.imageContainerM}
                style={{
                  width: "50vw",
                  padding: mobile ? "25px" : "20px",
                }}
              >
                <Image fluid src={popart} />
              </div>
            </div>
            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>
          </>
        ) : (
          <>
            <div className={styles.secondLineSponsorContainer}>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "20%",
                  padding: "30px",
                }}
              >
                <Image fluid src={claro} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",

                  width: "20%",
                  padding: "30px",
                }}
              >
                <Image fluid src={pedidosya} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "20%",
                  padding: "30px",
                }}
              >
                <Image fluid src={didi} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "15%",
                  padding: "28px",
                }}
              >
                <Image fluid src={solodeportes} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  width: "20%",
                  padding: "35px",
                }}
              >
                <Image fluid src={aireuropa} />
              </div>
            </div>

            {/* -------- SEGUNDA LINEA -------*/}
            <div className={mobile ? styles.middleLineM : styles.middleLine}>
              {" "}
            </div>

            <div className={styles.secondLineSponsorContainer}>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "25%",
                  padding: "30px",
                }}
              >
                <Image fluid src={correoargentino} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "25%",
                  padding: "28px",
                }}
              >
                <Image fluid src={enigma} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  borderRight: "1.7px solid rgba(231, 230, 230, 0.42)",
                  width: "20%",
                  padding: "20px",
                }}
              >
                <Image fluid src={areaticket} />
              </div>
              <div
                className={styles.imageContainer}
                style={{
                  width: "25%",
                  padding: "35px",
                }}
              >
                <Image fluid src={popart} />
              </div>
            </div>
          </>
        )}

        {/* <Image className={styles.linkdesktop} fluid src={sponsor2} alt="" /> */}
      </div>
    </div>
  )
}
