import React, { useState } from "react"
import { Form, Row, Col, Button, Alert } from "react-bootstrap"
import { Checkbox } from "antd"
import sectionStyles from "../../styles/quilmesrock/sectionModal.module.css"
import styles from "../../styles/quilmesrock/promocionesModal.module.css"
import { ToastContainer, toast } from "react-toastify"
import localidades from "./localidades.json"
import border from "../../images/quilmesrock/bottom.png"
import "react-toastify/dist/ReactToastify.css"
import Cookies from "js-cookie";

export default function Promociones({handleClose}) {
  const [errorMsg, setErrorMsg] = useState('');
  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"
  // const api = "http://localhost:8080/api"
  const emptyform = {
    name: "",
    date: "",
    mail: "",
    phone: "",
    state: "",
    location: "",
  }
  const [form, setForm] = useState(emptyform),
    [checked, setChecked] = useState(false),
    handleChange = ({ target }) => {
      const { name, value } = target
      setForm({ ...form, [name]: value })
    },
    handleSubmit = e => {
      e.preventDefault()
      if (form.name.length < 1) { setErrorMsg('El nombre es demasiado corto'); return; }
      if (form.mail.length < 3) { setErrorMsg('El email es demasiado corto'); return; }
      if (!form.date) { setErrorMsg('Seleccione una fecha valida'); return; }
      if (form.phone.length < 5) { setErrorMsg('El telefono es demasiado corto'); return; }
      if (!form.state) { setErrorMsg('Debe seleccionar un provicia'); return; }
      let td = Cookies.get("_td")
      let obj = {
        name: form.name,
        date: form.date,
        mail: form.mail,
        phone: form.phone,
        state: form.state,
        location: form.location,
        td,
      }
      fetch(`${api}/quilmesrock/promotion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }).then(response => {
        // console.log("responsee", response)
        if (response.ok) {
          setForm(emptyform)
          toast.success("Mensaje Enviado", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          handleClose(true)
        } else {
          // mostrar mensaje de error
          toast.error(errorMsg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
    }

  return (
    <div className={sectionStyles.container} name="promociones">
      <ToastContainer />
      <div className={sectionStyles.column}>
        {/* <h4 className={sectionStyles.titulo}>NOVEDADES</h4> */}
        <h2 className={sectionStyles.subtitulo}>ANOTATE Y <br/> PARTICIPÁ POR ENTRADAS </h2>

      </div>
      <div className={styles.closeButton} onClick={handleClose}> X</div>

      <div className={styles.promociones}>
        <Form
          className={styles.promociones}
          onSubmitCapture={handleSubmit}
          style={{ margin: "0" }}
        >
          <Row className={styles.formulario}>
            <Col xs={12} md={10}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>
                  Nombre y apellido
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={form.name}
                  placeholder="Nombre y apellido"
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={10}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="mail"
                  value={form.mail}
                  placeholder="Email"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={6} md={5}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>
                  Fecha de nacimiento
                </Form.Label>
                <Form.Control
                  required
                  type="date"
                  name="date"
                  value={form.date}
                  placeholder="Nombre y apellido"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={6} md={5}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>Telefono</Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="phone"
                  value={form.phone}
                  placeholder="Teléfono"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={6} md={5}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>Provincia</Form.Label>
                <Form.Control
                  required
                  name="state"
                  value={form.state}
                  as="select"
                  onChange={handleChange}
                >
                  <option>Provincia</option>
                  <option value="Capital Federal"> Capital Federal </option>
                  <option value="Buenos Aires">Buenos Aires</option>
                  <option value="Catamarca">Catamarca</option>
                  <option value="Chaco">Chaco</option>
                  <option value="Chubut">Chubut</option>
                  <option value="Córdoba">Córdoba</option>
                  <option value="Corrientes">Corrientes</option>
                  <option value="Entre Ríos">Entre Ríos</option>
                  <option value="Formosa">Formosa</option>
                  <option value="Jujuy">Jujuy</option>
                  <option value="La Pampa">La Pampa</option>
                  <option value="La Rioja">La Rioja</option>
                  <option value="Mendoza">Mendoza</option>
                  <option value="Misiones">Misiones</option>
                  <option value="Neuquén">Neuquén</option>
                  <option value="Río Negro">Río Negro</option>
                  <option value="Salta">Salta</option>
                  <option value="San Juan">San Juan</option>
                  <option value="San Luis">San Luis</option>
                  <option value="Santa Cruz">Santa Cruz</option>
                  <option value="Santa Fe">Santa Fe</option>
                  <option value="Santiago del Estero">
                    Santiago del Estero
                  </option>
                  <option value="Tierra del Fuego">Tierra del Fuego</option>
                  <option value="Tucumán">Tucumán</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={6} md={5}>
              <Form.Group>
                <Form.Label className={styles.formtitle}>Localidad</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  as="select"
                  value={form.location}
                  placeholder="Localidad"
                  onChange={handleChange}>
                  <option>Localidad</option>
                  {localidades
                    .sort((a, b) => {
                      const n = a.Localidad.localeCompare(b.Localidad);
                      return n == 0 && a.Localidad !== n.Localidad ? b.Localidad.localeCompare(a) : n;
                    })
                    .filter((localidad) => {
                      return localidad.Provincia === form.state;
                    })
                    .map((option) => (
                      <option value={option.Localidad}>{option.Localidad}</option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={12} md={10} className="d-inline-flex ">
              <Checkbox
                className={styles.checkbox}
                type="checkbox"
                checked={checked}
                onChange={({ target }) => setChecked(target.checked)}
              />
              <p className={styles.checkboxtext}>
                He leído y acepto <a className={styles.linksa} href="https://www.quilmes.com.ar/politicadeprivacidad.pdf" target="_blank"> Bases y Condiciones</a>,<a className={styles.linksa} href="https://www.quilmes.com.ar/politicadeprivacidad.pdf" target="_blank"> Políticas de Privacidad</a>,
                <a className={styles.linksa} href="https://www.quilmes.com.ar/terminosycondiciones.pdf" target="_blank"> Términos y Condiciones</a> y activaciones de marketing
              </p>
            </Col>
          </Row>
          <Alert variant="danger" show={errorMsg !== ''} onClose={() => setErrorMsg('')} dismissible>
            {errorMsg}
          </Alert>
          <Button className={styles.boton} disabled={!checked} type="submit">
            ENVIAR
          </Button>
        </Form>
      </div>
    </div>
  )
}
