import React, { useState, useEffect } from "react"
import { Button, Container, Accordion, Card, Image } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"

import { IsMobileDevice } from "../../../utils/ismobile"

import styles from "../../styles/quilmesrock/lineup.module.css"
import sectionStyles from "../../styles/quilmesrock/section.module.css"

import border from "../../images/quilmesrock/bottom.png"
import fecha1 from "../../images/quilmesrock/date1.png"
import fecha2 from "../../images/quilmesrock/date2.png"
// import lineup from "../../images/quilmesrock/lineup.png"
import grilladia1 from "../../images/quilmesrock/grilla-dia1.jpg"
import grilladia2 from "../../images/quilmesrock/grilla-dia2.jpg"

import lineupmobile from "../../images/quilmesrock/lineupmobile.png"
import arrow from "../../images/quilmesrock/downarrow.png"

export default function Lineup() {
  const [isMobile, setIsMobile] = useState(false)
  const [grilla, setGrilla] = useState(false)
  const mobile = useMediaQuery({ query: "(max-width: 767px)" }),
    [activeKey, setActiveKey] = useState(0),
    selectKey = key => {
      setActiveKey(activeKey === key ? -1 : key)
      console.log(key)
    }

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div className={sectionStyles.container} name="lineup">
      <div className={sectionStyles.column}>
        <h4 className={sectionStyles.titulo}> LINE-UP</h4>
        <h2 className={sectionStyles.subtitulo}> CONOCÉ NUESTRO LINEUP</h2>
        <img src={border} alt="" />
      </div>
      <div className={styles.botonera}>
        <div className={styles.fecha1}>
          {/* <a
            href="https://ventas.areaticket.com.ar/default?p=82B84FBBA982F19E7529DDFDDAD726A767B86BDFD1504539&utm_source=quakmedia&utm_medium=google%20ads&utm_campaign=quilmes%20rock&gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqOGYHL3-QPDrx2Xq04gGgUfvxWisFdle3DoywkEyV22qxnLTi1Bzg0aAvueEALw_wcB"
            target="_blank"
            n
            className="text-decoration-none"
          > */}
          <Button
            className={styles.boton}
            onClick={() => {
              setGrilla(false)
            }}
          >
            SÁBADO
          </Button>
          {/* </a> */}
          <img src={fecha1} alt="30/4" className={styles.fecha1img} />
        </div>
        <div className={styles.fecha2}>
          {/* <a
            href="https://ventas.areaticket.com.ar/default?p=82B84FBBA982F19E7529DDFDDAD726A767B86BDFD1504539&utm_source=quakmedia&utm_medium=google%20ads&utm_campaign=quilmes%20rock&gclid=Cj0KCQjwuMuRBhCJARIsAHXdnqOGYHL3-QPDrx2Xq04gGgUfvxWisFdle3DoywkEyV22qxnLTi1Bzg0aAvueEALw_wcB"
            target="_blank"
            className="text-decoration-none"
          > */}
          <Button
            className={styles.boton}
            onClick={() => {
              setGrilla(true)
            }}
          >
            DOMINGO
          </Button>
          {/* </a> */}
          <img src={fecha2} alt="1/5" className={styles.fecha2img} />
        </div>
      </div>

      {isMobile ? (
        <Container>
          <img
                  src={grilla ? grilladia2 : grilladia1}
            alt=""
            style={{ margin: 0, width: "100%" }}
          />
        </Container>
      ) : (
        // <Accordion defaultActiveKey="" className={styles.accordion}>
        //   {/* //-------------------------------------------------------------------------- */}
        //   <div>
        //     <Accordion.Toggle
        //       as={Card.Header}
        //       eventKey="0"
        //       className={styles.accordionheader}
        //     >
        //       <div
        //         className={styles.accordioncardheader}
        //         onClick={() => selectKey("0")}
        //       >
        //         <div>MAIN STAGE</div>
        //         <Image
        //           src={arrow}
        //           alt=""
        //           className={
        //             activeKey === "0"
        //               ? styles.flechaactiva
        //               : styles.flechainactiva
        //           }
        //           width={20}
        //         />
        //       </div>
        //     </Accordion.Toggle>
        //     <Accordion.Collapse eventKey="0">
        //       <div className="w-100 d-flex justify-content-center align-items-center">
        //         <img
        //           src={lineupmobile}
        //           alt=""
        //           className={styles.mobilelineup}
        //         />
        //       </div>
        //     </Accordion.Collapse>
        //   </div>
        //   {/* //-------------------------------------------------------------------------- */}
        //   <div>
        //     <Accordion.Toggle
        //       as={Card.Header}
        //       eventKey="1"
        //       className={styles.accordionheader}
        //     >
        //       <div
        //         className={styles.accordioncardheader}
        //         onClick={() => selectKey("1")}
        //       >
        //         SECOND STAGE
        //         <Image
        //           src={arrow}
        //           alt=""
        //           width={20}
        //           className={
        //             activeKey === "1"
        //               ? styles.flechaactiva
        //               : styles.flechainactiva
        //           }
        //         />
        //       </div>
        //     </Accordion.Toggle>
        //     <Accordion.Collapse eventKey="1">
        //       <div className="w-100 d-flex justify-content-center align-items-center">
        //         <img
        //           src={lineupmobile}
        //           alt=""
        //           className={styles.mobilelineup}
        //         />
        //       </div>
        //     </Accordion.Collapse>
        //   </div>
        //   {/* //-------------------------------------------------------------------------- */}
        //   <div>
        //     <Accordion.Toggle
        //       as={Card.Header}
        //       eventKey="2"
        //       className={styles.accordionheader}
        //     >
        //       {" "}
        //       <div
        //         className={styles.accordioncardheader}
        //         onClick={() => selectKey("1")}
        //       >
        //         THIRD STAGE
        //         <Image
        //           src={arrow}
        //           alt=""
        //           width={20}
        //           className={
        //             activeKey === "1"
        //               ? styles.flechaactiva
        //               : styles.flechainactiva
        //           }
        //         />
        //       </div>
        //     </Accordion.Toggle>
        //     <Accordion.Collapse eventKey="2">
        //       <div className="w-100 d-flex justify-content-center align-items-center">
        //         <img
        //           src={lineupmobile}
        //           alt=""
        //           className={styles.mobilelineup}
        //         />
        //       </div>
        //     </Accordion.Collapse>
        //   </div>
        // </Accordion>
        <Container>
          <img
            src={grilla ? grilladia2 : grilladia1}
            alt=""
            style={{width: "100%" }}
          />
        </Container>
      )}
    </div>
  )
}
