import React from "react"
import { Link } from "react-scroll"
import FlechaSelected from "../../images/logos/flechaSelected.svg"

import logo from "../../images/quilmesrock/logo.png"
import logoIg from "../../images/logos/logoIg.svg"
import logoFb from "../../images/logos/logoFb.svg"
import logoTw from "../../images/logos/logoTw.svg"
import logoYou from "../../images/logos/logoYou.svg"
import { AiOutlineClose } from "react-icons/ai"

import styles from "../../styles/sideMenu.module.css"

const menuData = [
  {
    title: "HOME",
    path: "home",
  },
  {
    title: "LINEUP",
    path: "lineup",
  },
  {
    title: "INFORMACIÓN",
    path: "info",
  },
  {
    title: "SPONSORS",
    path: "sponsors",
  },
  {
    title: "TICKETS",
    path: "tickets",
  },
  {
    title: "NOVEDADES",
    path: "promociones",
  },
]

function SidebarRock({ setActiveMenu, activeMenu }) {
  return (
    <div className={!activeMenu ? styles.containerActive : styles.container}>
      <div className={styles.logoContainer}>
        <div />
        <img alt="Quilmes Rock" className={styles.logoQ} src={logo} />
        <AiOutlineClose
          onClick={() => setActiveMenu(!activeMenu)}
          className={styles.close}
        />
      </div>
      <div className={styles.options}>
        {menuData.map(item => {
          return (
            <Link
              key={item.path}
              onClick={() => setActiveMenu(!activeMenu)}
              className={styles.link}
              to={item.path}
            >
              <img
                alt=""
                className={styles.flechaSelected}
                src={FlechaSelected}
              />

              <span className={styles.title}>{item.title}</span>
            </Link>
          )
        })}
      </div>
      <div className={styles.socialIcons}>
        <a
          target="_blank"
          href="https://www.facebook.com/quilmescerveza/?ref=bookmarks"
          rel="noreferrer"
        >
          <img alt="logo facebook" src={logoFb} />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/Quilmes_Cerveza"
          rel="noreferrer"
        >
          <img alt="logo twitter" src={logoTw} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/cervezaquilmes/?hl=es-la"
        >
          <img alt="logo instagram" src={logoIg} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/user/Quilmes120Argentina"
        >
          <img alt="logo youtube" src={logoYou} />
        </a>
      </div>
    </div>
  )
}

export default SidebarRock
